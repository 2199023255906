<template>
  <div class="m-fb">
    <div class="footerBox">
      <div :class="[isMobile ? 'mobile_footer' : 'm-footer coreSize']">
        <!-- 左 -->
        <div :class="[isMobile ? 'm-about' : 'm-f-left']">
          <div class="m-f-main-about">
            <p class="m-f-title ">关于</p>
            <div class="m-f-linkBox">
              <ul>
                <li>
                  <router-link to="/pc/notice/4">关于我们</router-link>
                </li>
                <li>
                  <router-link to="/pc/notice/5">联系我们</router-link>
                </li>
                <!-- <li>
                  <router-link to="/pc/notice/3">隐私政策</router-link>
                </li> -->
                <li>
                  <router-link to="/pc/notice/1">用户协议</router-link>
                </li>
              </ul>
              <!-- <ul>
                <li>
                  <router-link to="/pc/notice/3">隐私政策</router-link>
                </li>
                <li>
                  <router-link to="/pc/notice/1">用户协议</router-link>
                </li>
              </ul> -->
            </div>
          </div>
          <div class="m-f-main-box">
            <p class="m-f-title">联系我们</p>
            <div class="m-f-linkBox">
              <ul>
                <li>工作时间：周一至周五 9:00-18:00</li>
                <li>意见反馈：support@medinsightech.com</li>
                <li>商务合作：support@medinsightech.com</li>
              </ul>
            </div>
          </div>
          <!-- 友情链接 -->
          <div class="m-f-main-link">
            <p class="m-f-title">友情链接</p>
            <div class="m-f-linkBox">
              <ul>
                <li>
                  <a href="https://mp.weixin.qq.com/s/VjCOoOca77eoa0JFH9pkBA" target="_blank">医学影像3D建模入门教程</a>
                </li>
                <li>
                  <a href="https://persptech-forum.oss-cn-beijing.aliyuncs.com/ctVideo%2Fdicom%E6%95%B0%E6%8D%AE%E8%84%B1%E6%95%8F%E5%B7%A5%E5%85%B7.exe"
                    download="">dicom数据脱敏工具</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 右 -->
        <div class="m-f-erCodeBox">
          <!-- <p class="m-f-title ">更多产品</p> -->
          <div class="m-f-erwBox" :class="{ 'qrCode': isMobile }">
            <div class="m-f-er on">
              <p class="m-f-title">普视3D APP</p>
              <p class="m-f-codeImg"><img class="m-f-er-img" src="@/assets/img/common/chat.png" alt=""></p>
              <p class="m-f-er-title">添加“普视云小助手”获取</p>
            </div>
            <div class="m-f-er">
              <p class="m-f-title ">信息平台</p>
              <img class="m-f-er-img" src="@/assets/img/common/3DErw.png" alt="">
              <p class="m-f-er-title">微信公众号</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 备案信息    -->
    <a href="https://beian.miit.gov.cn/#/Integrated/index" class="fb-bottom">沪ICP备2023009759号-2</a>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      isMobile: false,
    }
  },
  created () {
    this.showMobile()
  },
  methods: {
    showMobile () {
      var ua = navigator.userAgent;
      var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid;
      this.isMobile = isMobile ? true : false
    }
  }
}
</script>
<style scoped lang="less">
@import url("@/assets/css/footer.css");

.mobile_footer {
  padding: 30px 20px 20px;
}

.qrCode {
  margin-bottom: 20px;
}
</style>